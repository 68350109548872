<template>
  <div
    tabindex='0'
    @keydown.esc='navMenuClose'>
    <transition name='fade'>
      <nav v-if='showNavMenu' class='fixed top-0 left-0 h-full bg-white shadow-2xl p-16 text-left z-30 overflow-y-auto flex flex-col justify-between' style='width:420px;'>
        <ul class=' uppercase text-sm tracking-wide'>
          <li>
            <img :src='logoURL' class='mb-24 w-24' />
          </li>
          <li>
            <router-link :to='{ name: "Dashboard" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <view-grid-icon class='inline-block mr-2' style='margin-top: -4px;' /> Dashboard
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "Portfolios" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <collection-icon class='inline-block mr-2' style='margin-top: -4px;' /> Portfolio
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "LpPortfolios" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <collection-icon class='inline-block mr-2' style='margin-top: -4px;' /> Lp Investment
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "HoldingsReport" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <presentation-chart-bar-icon class='inline-block mr-2' style='margin-top: -4px;' /> Holdings Report
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "CorporateAnnouncements" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <speakerphone-icon class='inline-block mr-2' style='margin-top: -4px;' /> Announcements
            </router-link>
          </li>
          <li v-if='canViewProprietaryContent'>
            <a class='block py-5 nav-link opacity-50' @click='showPiSubmenu=!showPiSubmenu'><code-icon class='inline-block mr-2' style='margin-top: -4px;' /> Portfolio-PI</a>
            <ul v-show='showPiSubmenu'>
              <li>
                <router-link :to='{ name: "ProprietaryPortfolio" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
                  <collection-icon class='inline-block ml-4 mr-2' style='margin-top: -4px;' /> 고유재산 보유현황
                </router-link>
              </li>
              <li>
                <router-link :to='{ name: "LedgerPiCodes" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
                  <code-icon class='inline-block ml-4 mr-2' style='margin-top: -4px;' /> 고유재산 코드관리
                </router-link>
              </li>
              <li>
                <router-link :to='{ name: "PiFills" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
                  <shopping-cart-icon class='inline-block ml-4 mr-2' style='margin-top: -4px;' /> 체결소계
                </router-link>
              </li>
              <li>
                <router-link :to='{ name: "PiDivs" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
                  <shopping-cart-icon class='inline-block ml-4 mr-2' style='margin-top: -4px;' /> 현금배당
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <a class='block py-5 nav-link opacity-50' @click='showCodeSubmenu=!showCodeSubmenu'><code-icon class='inline-block mr-2' style='margin-top: -4px;' /> 코드관리</a>
            <ul v-show='showCodeSubmenu'>
              <li>
                <router-link :to='{ name: "Funds" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
                  <code-icon class='inline-block ml-4 mr-2' style='margin-top: -4px;' /> Funds
                </router-link>
              </li>
              <li>
                <router-link :to='{ name: "DailyPerformances" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
                  <code-icon class='inline-block ml-4 mr-2' style='margin-top: -4px;' /> 자산정보관리
                </router-link>
              </li>
              <li>
                <router-link :to='{ name: "Industries" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
                  <code-icon class='inline-block ml-4 mr-2' style='margin-top: -4px;' /> 업종관리
                </router-link>
              </li>
              <li>
                <router-link :to='{ name: "VentureIndustries" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
                  <code-icon class='inline-block ml-4 mr-2' style='margin-top: -4px;' /> 뉴딜관리
                </router-link>
              </li>
              <li>
                <router-link :to='{ name: "LedgerCodes" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
                  <code-icon class='inline-block ml-4 mr-2' style='margin-top: -4px;' /> Portfolio 코드관리
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link :to='{ name: "Ipo" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <information-circle-icon class='inline-block mr-2' style='margin-top: -4px;' /> IPO
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "MyApprovals" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <bell-icon class='inline-block mr-2' style='margin-top: -4px;' /> 승인 내역
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "Files" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <document-icon class='inline-block mr-2' style='margin-top: -4px;' /> 파일 관리
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "History" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <bell-icon class='inline-block mr-2' style='margin-top: -4px;' /> 업데이트 내역
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "Contacts" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <users-icon class='inline-block mr-2' style='margin-top: -4px;' /> 연락처
            </router-link>
          </li>
          <li class='mt-12'>
            <router-link :to='{ name: "Entities" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <briefcase-icon class='inline-block mr-2' style='margin-top: -4px;' /> Entities
            </router-link>
          </li>
          <li v-if='isAdmin'>
            <router-link :to='{ name: "CustomFieldTemplates" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <server-icon class='inline-block mr-2' style='margin-top: -6px;' /> 사용자 컬럼 정의
            </router-link>
          </li>
          <li v-if='isTeamLead || isAdmin'>
            <router-link :to='{ name: "ManageAccess" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <cog-icon class='inline-block mr-2' style='margin-top: -6px;' /> 화면 권한 관리
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "MyContact" }' class='block py-5 nav-link opacity-50' @click.native='clickNavMenuLink'>
              <phone-icon class='inline-block mr-2' style='margin-top: -4px;' /> 나의 연락처
            </router-link>
          </li>
        </ul>
        <logout-button class='text-left pl-8 mt-16' />
      </nav>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BriefcaseIcon,
  BellIcon,
  PresentationChartBarIcon,
  CogIcon,
  CodeIcon,
  CollectionIcon,
  DocumentIcon,
  InformationCircleIcon,
  PhoneIcon,
  ServerIcon,
  SpeakerphoneIcon,
  UsersIcon,
  ViewGridIcon,
  ShoppingCartIcon,
} from '@vue-hero-icons/outline'
import LogoutButton from '@/components/LogoutButton.vue'

export default {
  name: 'NavMenu',
  components: {
    BriefcaseIcon,
    BellIcon,
    CogIcon,
    CodeIcon,
    CollectionIcon,
    DocumentIcon,
    PresentationChartBarIcon,
    InformationCircleIcon,
    PhoneIcon,
    SpeakerphoneIcon,
    ServerIcon,
    UsersIcon,
    ViewGridIcon,
    LogoutButton,
    ShoppingCartIcon,
  },
  data () {
    return {
      logoURL: process.env.VUE_APP_CLIENT_BASE_URL + '/logo_reach_horizontal.svg',
      showCodeSubmenu: false,
      showPiSubmenu: false,
      showTradingSubmenu: true,
      showScheduleSubmenu: false,
    }
  },
  computed: {
    ...mapState([
      'showNavMenu'
    ]),
    ...mapState('companySettings', [
      'companySettings',
    ]),
    ...mapState('users', [
      'currentUserInfo'
    ]),
    isAdmin () {
      return this.currentUserInfo.roles.includes('admin')
    },
    isTeamLead () {
      return this.currentUserInfo.roles.includes('TeamLead')
    },
    canViewProprietaryContent () {
      return this.currentUserInfo.roles.includes('proprietary')
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose',
      'navMenuClose',
    ]),
    clickNavMenuLink () {
      this.sidepanelClose()
      this.navMenuClose()
    },
  },
}
</script>

<style scoped lang='scss'>
.nav-link {
  @apply transition duration-150 ease-in-out;
}

.nav-link.router-link-active {
  @apply opacity-100;
  background-color: hsla(204, 41%, 47%, 0.1);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  margin-left: -1.5rem;
  padding-left: 1.5rem;
  margin-right: -4rem;
  padding-right: 4rem;
}

.nav-link:hover {
  @apply opacity-100;
}

.fade-enter-active,
.fade-leave-active {
  transition: all .2s;
}

nav.fade-enter,
nav.fade-leave-to {
  opacity: 0;
  left: -420px;
}

</style>
